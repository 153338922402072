.darken-screen {
    box-shadow:0 0 0 5000px rgba(0,0,0,0.65);
}

.modal-appear {
    opacity: 0;
}
.modal-appear-active {
    opacity: 1;
    transition: opacity 300ms;
}
.modal-exit {
    opacity: 1;
}
.modal-exit-active {
    opacity: 0;
    transition: opacity 300ms;
}